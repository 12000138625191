const header = document.querySelector('.header');
const nav = document.querySelector('.navigation');
const hamburger = document.querySelector('#hamburger');
const body = document.querySelector('body');

hamburger.addEventListener('click', () => {
    header.classList.toggle('active');
    hamburger.classList.toggle('open');
    body.classList.toggle('noOverflow');
    setAria();
})

// Utility Classes
const setAria = () => {
    const menuAria = hamburger.getAttribute('aria-label');
    if (menuAria === 'Open navigation menu') {
        hamburger.setAttribute('aria-label', 'Close navigation menu')
        return
    }
    hamburger.setAttribute('aria-label', 'Open navigation menu')
}